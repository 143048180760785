<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div class="all-subscribed-item" v-else>
      <div class="sohoj-category subscribed-service">
        <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.title : '' }}</h3>
        <ul>
          <li v-for="data in dataDetails.list">
              <a href="javascript:void(0)">
                <div class="category-box">
                <img :src="!_.isEmpty(data) ? data.image : ''" alt="">
                  <h3>{{data.text}}</h3>
                <button type="button" @click.prevent="visitSite(!_.isEmpty(data) ? data.visit_link : '') " class="unsub-page-btn visit-btn" data-service="gamer_heaven">{{ !_.isEmpty(data) ? data.visit_btn_text : '' }}</button>
                <button type="button" @click="unSubSubmit(data.service)" data-option_id="133" data-language="1" data-service_id="8" data-service="gamer_heaven" class="unsub-page-btn unsb-btn">{{ !_.isEmpty(data) ? data.unsub_btn_text : '' }}</button>
                </div>
              </a>
          </li>
        </ul>
      </div>
      <div @click.prevent="reCheck()" class="single-link"><a href="javascript:void(0)" id="reg-status-check" style=" font-weight: normal !important;">
        <img :src="pictureBaseUrl+'/digi/images/sync-rotate-reload-refresh-icon.svg'"  alt="" style="height: 20px!important;"> RECHECK SUBSCRIPTION STATUS
      </a></div>
    </div>
  </div>
</template>

<script>
import { saveUserJourney } from '@/services/api/saveUserJourney'
import axios from 'axios'
import {updateEvent} from "@/services/api/vivr-engine-helper";

export default {
  name: 'mySubscription',
  data () {
    return {
      apiUrl: 'content/unsub-page',
      dataDetails: {},
      msisdn: localStorage.getItem('msisdn') ?? '',
      languageId: sessionStorage.getItem('language') ?? '1',
      is_loading: true,
      subscriptionStatus: '',
      sessionId: sessionStorage.getItem('sessionId'),
      service: {},
      operatorId: localStorage.getItem('operatorId'),
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    async init () {
      this.service = this.$route.params.service
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })
      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, null, { params: { languageId: this.languageId, operatorId: this.operatorId, msisdn: this.msisdn } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    unSubSubmit (service) {
      this.$router.push({ name: 'unsubConfirmationPage', params: { service: service } })
    },
    visitSite (url) {
      location.href = url
    },
    reCheck () {
      this.init()
    }
    // this.$router.push(this.dataDetails.service.visit_url
  }
}
</script>

<style scoped>

</style>
